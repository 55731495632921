import React from "react"
import styled from 'styled-components'
import Lottie from 'lottie-react';
import animationData from "../lotties/loading.json";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Loading = () => {

    const style = {
		height:300,
		width:300
	};

	return (
		<Wrapper>
            <Lottie
                animationData={animationData}
                loop={true}
                style={style}/>
        </Wrapper>
	)
}
