import React, { useState } from "react"
import moment from 'moment'

export const Date = ({ date, format }) => {

	const [dateFormat] = useState(format ? format : 'Do MMM YYYY')

	return (
		<> {date && moment(date.toDate()).format(dateFormat)}</>
	)
}
