import React, { useRef } from "react"

import { Field } from "../../styles/"

export const InputField = ({ autocomplete, align, label, name, placeholder, required, type, value, setValue }) => {

	const inputRef = useRef();

	function handleChange(e) {
		e.persist();
		setValue(currentValues => ({
			...currentValues,
			[name]: inputRef.current.value
		}))
	}

	return (
		<Field align={align}>
			<label>
				{label &&
					<p>{label}{required && '*'}</p>
				}
				<input
					type={ type }
					name={ name }
					placeholder={ placeholder && placeholder + (required && '*') }
					defaultValue={ value }
					ref={inputRef}
					onChange={ handleChange }
					required={ required }
					autoComplete={ autocomplete ?? 'off' }/>
			</label>
		</Field>
	)
}
