import { createSlice } from '@reduxjs/toolkit'

export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: {
    user: null,
  },
  reducers: {
    updateAdminUser: (state, action) => {
      state.user = action.payload
    },
  }
})

export const { updateAdminUser } = adminUserSlice.actions

export const selectAdminUser = state => state.adminUser

export default adminUserSlice.reducer