import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//redux
import { useDispatch } from 'react-redux'
import { updateAdminUser } from '../store/reducers/adminUserSlice';

//firebase
import { auth } from '../utils/firestore';
import { onAuthStateChanged } from "firebase/auth";

//components
import { Loading } from "../special/"

import { Header } from "./"

export const AdminPage = ({ children }) => {

	const [loading, setLoading] = useState(true)

	const dispatch = useDispatch();

	const checkUser = () => {
		// console.log('Checking user')
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// console.log('user found')
			  	dispatch(updateAdminUser(user.uid))
				setLoading(false)
			} else {
				// console.log('no user')
				dispatch(updateAdminUser(null))
				setLoading(false)
			}
		});
	}

	useEffect(() => {
		checkUser()
    })

	return (
			<>
				{loading ?
					<Loading/>
				:
					<>
						<Header/>
						{ children }
					</>
				}
			</>
	)
}

AdminPage.propTypes = {
  children: PropTypes.node.isRequired,
}
