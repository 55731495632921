import React, { useState, useEffect } from "react"

import { auth } from '../utils/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { InputField } from "../partials/"
import { Button, Component, ErrorText, TextGroup } from "../styles/"
import { Loading } from "./"

export const DashboardLogin = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [formValues, setFormValues] = useState({
		emailAddress: '',
        password: ''
	})

    const handleSubmit = (evt) => {
		evt.preventDefault()
        setLoading(true)  

        signInWithEmailAndPassword(auth, formValues.emailAddress, formValues.password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user)
            setLoading(false)
        })
        .catch((error) => {
            setError(error.message)
            setLoading(false)
        });
	}

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="500px">
                    <TextGroup center={true}>
                        <h1>Dashboard login</h1>
                    </TextGroup>
                    <form onSubmit={ handleSubmit }>
                        <InputField
                            type="email"
                            placeholder="Email Address"
                            name="emailAddress"
                            value={ formValues.emailAddress }
                            setValue={ setFormValues }
                            autoComplete="on"
                            align="center"
                            required/>
                        <InputField
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={ formValues.password }
                            setValue={ setFormValues }
                            align="center"
                            required/>
                        <Button full="true" submit="true" type="submit">Submit</Button>
                        {error &&
                            <ErrorText>
                                <p>{ error }</p>
                            </ErrorText>                         
                        }
                    </form>
                </Component>
            }
        </>
        
	)
}
