import styled from 'styled-components'
import { GreyLight } from '../variables/'

export const Component = styled.div`
	background: ${GreyLight.primary};
	color: ${GreyLight.secondary};
	border-radius: 20px;
	padding: 30px;
	max-width: ${props => props.maxWidth ? props.maxWidth : "none"};
	margin: 0 auto;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`
