import React from "react"
import styled from 'styled-components'

import { Restrict } from "../styles/"
import { FontSize, GreyLight } from "../variables/"

const Wrapper = styled.footer`
	padding: 20px;
	background: ${GreyLight.primary}
`
const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	${FontSize.xxsm}
	a {
		color: inherit;
		text-decoration: none;
		margin-right: 10px;
		&:hover {
			text-decoration: underline;
		}
	}
`

export const Footer = () => {

	return (
		<Wrapper>
			<Restrict>
				<Content>
					<a href="https://goodwinbarrett.co.uk/privacy-policy/" target="_blank" rel="noreferrer noopener">Goodwin Barrett Privacy policy</a>
					<a href="https://www.safedox.co.uk/privacy-policy" target="_blank" rel="noreferrer noopener">SafeDox Privacy policy</a>
				</Content>
			</Restrict>
		</Wrapper>
	)
}
