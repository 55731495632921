import { createSlice } from '@reduxjs/toolkit'

export const claimSlice = createSlice({
  name: 'claim',
  initialState: {
    id: null,
    data: null
  },
  reducers: {
    updateClaimId: (state, action) => {
      state.id = action.payload
    },
    updateClaimData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { updateClaimId, updateClaimData } = claimSlice.actions

export const selectClaim = state => state.claim

export default claimSlice.reducer