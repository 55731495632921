import React, { useRef, useState } from "react"
import styled from 'styled-components'

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Field } from "../../styles/"
import { Grey, FontSize } from "../../variables/"

const AddressOptions = styled.div`
	position: absolute;
	background: white;
	padding: 10px;
`
const Option = styled.div`
	${FontSize.xsm}
	padding: 10px;
	border-bottom: 1px solid ${Grey.primary};
	cursor: pointer;
	&:hover {
		background: ${Grey.primary}; 
	}
	&:last-child {
		border: none;
	}
`

export const AddressField = ({ label, name, placeholder, required, value, setValue }) => {

	const [showPredictions, setShowPredictions] = useState(false);

	const inputRef = useRef();

	const {
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey: process.env.GATSBY_FIREBASE_API_KEY,
		debounce: 1000,
		options: { 
			componentRestrictions: { country: 'gb' }, 
		},
	});

	function handleSelect(place) {
		inputRef.current.value = place.description
		setValue(currentValues => ({
			...currentValues,
			[name]: place.description
		}))
		setShowPredictions(false)
	}


	return (
		<Field>
			<label>
				{label &&
					<p>{label}{required && '*'}</p>
				}
				<input
					type="text"
					name={ name }
					placeholder={ placeholder && placeholder + (required && '*') }
					required={ required }
					defaultValue={ value }
					ref={inputRef}
					autoComplete="off"
					onChange={(evt) => {
						setShowPredictions(true)
						getPlacePredictions({ input: evt.target.value });
					}}
					loading={isPlacePredictionsLoading}/>
			</label>
			{showPredictions && placePredictions.length > 0 &&
				<AddressOptions>
					{placePredictions.map((item, i) => {
						return (
							<Option key={i} onClick={() => handleSelect(item)}>
								{item.description}
							</Option>
						)
					})}
				</AddressOptions>
			}
		</Field>
	)
}
