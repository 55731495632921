import React, { useState } from "react"

import { db } from '../utils/firestore';
import { doc, setDoc } from "firebase/firestore"; 

import { Button, ButtonWrapper, Component, TextGroup, ErrorText } from "../styles/"
import { SignatureField } from "../partials/"
import { Loading } from "./"

export const ApiSign = ({ id }) => {

    const [loading, setLoading] = useState(false);
    const [signature, setSignature] = useState(null)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const submitClaim = async () => {
        const dateSubmitted = new Date()
        await setDoc(doc(db, "api_signatures", id), {
            id: id,
            signature: signature,
        }, { merge: true }).then(() => {
            setLoading(false)
            setError(null)
            setSuccess('Your signature has been submitted successfully')
        }).catch((error) => {
            setSuccess(null)
            setError(error.message)
            setLoading(false)
        });;
    }

    const handleSubmit = (evt) => {
		evt.preventDefault()
        submitClaim() 
	}

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="800px">
                    {id ? 
                        success ?
                            <TextGroup>
                                <h1>{success}</h1>
                            </TextGroup>
                        :   
                            <>
                                <TextGroup>
                                    <h1>Please sign below</h1>
                                </TextGroup>
                                {signature ?
                                    <>
                                        <img alt='signature' src={signature} />
                                        <ButtonWrapper>
                                            <Button submit="true" onClick={ handleSubmit }>Submit signature</Button> 
                                        </ButtonWrapper> 
                                    </>
                                :  
                                    <SignatureField sigImage={ signature } setSigImage={ setSignature } errorCallback={ setError }/>
                                }
                                {error &&
                                    <ErrorText>
                                        <p>{ error }</p>
                                    </ErrorText>                         
                                }
                            </>
                        
                    :   
                        <TextGroup>
                            <h1>Id query parameter required</h1>
                        </TextGroup>
                    }
                </Component>
            }
        </>
	)
}
