import React from "react"
import styled from 'styled-components'

//redux
import { useSelector } from 'react-redux'
import { selectAdminUser } from '../store/reducers/adminUserSlice'
import { selectClaim } from '../store/reducers/claimSlice'

//components
import { AdminLogout } from "../special/"
import { Logo } from "../partials/"
import { Restrict } from "../styles/"
import { BreakPoint, FontSize } from "../variables/"

const Wrapper = styled.header`
	padding: 20px;
	box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);
	background: white;
`
const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	@media ${BreakPoint.lg} { 
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`
const LogoWrapper = styled.div`
	margin-bottom: 15px;
	&:last-child{
		margin-bottom: 0;
	}
	@media ${BreakPoint.lg} { 
		margin-bottom: 0;
	}
`
const ClaimId = styled.div`
	${FontSize.xsm}
	font-weight: bold;
`

export const Header = () => {

	const adminUser = useSelector(selectAdminUser);
	const claim = useSelector(selectClaim);

	return (
		<Wrapper>
			<Restrict>
				<Content>
					<LogoWrapper>
						<Logo/>
					</LogoWrapper>	
					{adminUser.user ?
						<AdminLogout/>
					: claim.id &&
						<ClaimId>Claimant: { claim.id }</ClaimId>
					}	
				</Content>
			</Restrict>
		</Wrapper>
	)
}
