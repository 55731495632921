import React from "react";
import styled from 'styled-components'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Field } from "../../styles/"
import { GreyLight } from "../../variables/"

const Wrapper = styled.div`
    display: flex;
    width: 280px;
    max-width: 100%;
`

export const DatePickerField = ({startDate, endDate, onChange}) => {


    return (
        <Wrapper>
            <Field bgColour={GreyLight} align="right">
                <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="d MMM yyyy"
                    selectsRange
                    withPortal/>
            </Field>
        </Wrapper> 
    );
};