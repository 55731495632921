import React, { useRef } from "react"

import { Field } from "../../styles/"

export const SelectField = ({ label, name, onChange, options, required, short, value, setValue }) => {

	const selectRef = useRef();

	function handleChange(e) {
		e.persist();
		setValue(currentValues => ({
			...currentValues,
			[name]: selectRef.current.value
		}))
	}

	return (
		<Field short={ short }>
			<label>
				<p>{label}{required && '*'}</p>
				<select 
					name={ name } 
					defaultValue={ value }
					ref={selectRef}
					onChange={ handleChange }
					required={ required }>
					<option value="" disabled defaultValue>Select</option>
                    {options.map((option, i) => {
						return (
							<option key={i} value={ option.value }>{ option.label }</option>
						)
					})}
                </select>
			</label>
		</Field>
	)
}
