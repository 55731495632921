import React, { useState, useEffect } from "react"

//redux
import { useDispatch } from 'react-redux'
import { updateClaimId } from '../store/reducers/claimSlice';

//firebase
import { auth, db } from '../utils/firestore';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";

//components
import { InputField } from "../partials/"
import { Button, ButtonWrapper, Component, ErrorText, TextGroup } from "../styles/"
import { Loading } from "./"

export const ClaimLogin = ({editClaimCallback}) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [secure, setSecure] = useState(process.env.GATSBY_SECURE);
    const [linkSent, setLinkSent] = useState(false);
    const [dataFound, setDataFound] = useState(false);
    const [claimName, setClaimName] = useState(null);
    const [claimSubmitted, setClaimSubmitted] = useState(false);
    const [error, setError] = useState(null)
    const [formValues, setFormValues] = useState({
		emailAddress: '',
	})

    const actionCodeSettings = {
        url: process.env.GATSBY_SITE_URL,
        handleCodeInApp: true
    };

    const sendSecureLink = async () => {
        setLoading(true)
        sendSignInLinkToEmail(auth, formValues.emailAddress, actionCodeSettings)
        .then(() => {
            window.localStorage.setItem('emailForSignIn', formValues.emailAddress);
            setLinkSent(true)
            setLoading(false)
        })
        .catch((error) => {
            setError(error.message)
            setLoading(false)
        });
    }

    const startClaim = async () => {
        dispatch(updateClaimId(formValues.emailAddress))
        editClaimCallback(true)
    }

    const checkEmail = async () => {
		const docSnap = await getDoc(doc(db, "claims", formValues.emailAddress)); 
		if (docSnap.exists()) {
            setDataFound(true)
            const data = docSnap.data()
            setClaimName(data.firstName)
            if(data.dateClaimSubmitted) {
                setClaimSubmitted(true)
            }
		} else {
			startClaim(formValues.emailAddress)
		}
		setLoading(false)
	}

    const handleSubmit = (evt) => {
		evt.preventDefault()
        setLoading(true)
        if(secure !== 'false') {
            sendSecureLink()
        } else {
            checkEmail()
        }
	}

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="500px">
                    {!linkSent ?
                        dataFound ?
                            <>
                                <TextGroup center={true}>
                                    <h1>Welcome back {claimName}</h1>
                                    <p>It looks like you have already {claimSubmitted ? 'submitted' : 'started'} a claim with us.</p>
                                </TextGroup>
                                <ButtonWrapper>
                                    <Button onClick={() => sendSecureLink()} full="true" submit="true" type="submit">
                                        {claimSubmitted ? 'Review' : 'Continue'} claim
                                    </Button>
                                    {!claimSubmitted &&
                                        <Button onClick={() => startClaim()} full="true" type="submit">Start new claim</Button>
                                    }
                                </ButtonWrapper>
                            </> 
                        : 
                            <>  
                                <TextGroup center={true}>
                                    <h1>Start your claim today</h1>
                                    <p>Enter you email address below to start a new claim or find an existing one.</p>
                                </TextGroup>
                                <form onSubmit={ handleSubmit }>
                                    <InputField
                                        type="email"
                                        placeholder="Email Address"
                                        name="emailAddress"
                                        value={ formValues.emailAddress }
                                        setValue={ setFormValues }
                                        autocomplete="on"
                                        align="center"
                                        required/>
                                    <Button full="true" submit="true" type="submit">Submit</Button>
                                    {error &&
                                        <ErrorText>
                                            <p>{ error }</p>
                                        </ErrorText>                         
                                    }
                                </form>
                            </>
                    :
                        <TextGroup center={true}>
                            <h1>We've sent a secure link to your inbox</h1>
                            <p>To keep your data secure we need to verify your email address. Check your inbox and click on the secure link to continue.</p>
                            <p>Be sure to check your junk folder incase it landed in there.</p>
                            <p>You may close this window.</p>
                        </TextGroup>
                    }
                </Component>
            }
        </>
        
	)
}
