import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	margin-top: 20px;
	margin-bottom: -10px;
	> * {
		margin-right: 10px;
		margin-bottom: 10px;
	}
`
