import React from "react"
import { PDFViewer } from '@react-pdf/renderer';

import { ClaimContractPDF } from "./"

import styled from 'styled-components'

const Wrapper = styled(PDFViewer)`
	width: 100%;
	aspect-ratio: 70/99;
	border: none;
`

export const ViewClaim = ({ claimData }) => {

	return (
		<>
			{claimData &&
				<Wrapper showToolbar={false}>
					<ClaimContractPDF claimData={claimData} />
			  	</Wrapper>
			}
		</>
	)
}
