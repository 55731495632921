import React from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import LogoImage from "../../images/logos/safedox-logo.svg"

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`
const ClientLogo = styled.div`
	display: block;
`
const SafeDoxBrand = styled.div`
	margin-left: 20px;
	p {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom:3px;
	}
`
const SafeDoxLogo = styled(LogoImage)`
	width: 100%;
	max-width: 150px;
`

export const Logo = () => {

	return (
		<Wrapper>
			<ClientLogo>
				<StaticImage placeholder="none" src="../../images/logos/gb-logo.png" width={260} alt="logo" />
			</ClientLogo>
			<SafeDoxBrand>
				<p>Powered by</p>
				<SafeDoxLogo/>
			</SafeDoxBrand>
		</Wrapper>
	)
}
