import React, { useState, useRef } from "react"
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'

import { Button, ButtonWrapper } from "../../styles/"
import { FontSize, Grey } from "../../variables/"

const Wrapper = styled.div`
    background: ${Grey.primary};
    border-radius: 5px;
    padding: 15px;
`
const IntroText = styled.div`
    margin-bottom: 10px;
    ${FontSize.xsm}
`
const CanvasWrapper = styled.div`
	background: white;
    border-radius: 5px;
    overflow: hidden;
    width: 300px;
`
const ErrorText = styled.div`
    margin-top: 10px;
    ${FontSize.xsm}
`

export const SignatureField = ({ sigImage, setSigImage, errorCallback }) => {

    const [error, setError] = useState(null)
    const [isEmpty, setIsEmpty] = useState(true)

    let sigPad = useRef(null)

    const handleBegin = () => {
        setError(null)
        errorCallback(null)
        setIsEmpty(false)
    }
    const handleClear = () => {
        errorCallback(null)
        setIsEmpty(true)
        if(sigImage) {
            setSigImage(null)
        } else {
            sigPad.clear()

        }
    }
    const handleSave = () => {
        errorCallback(null)
        if(sigPad.isEmpty()) {
            setError('Please add your signature before saving')
        } else {
            setSigImage(sigPad.getTrimmedCanvas().toDataURL('image/png'))
        } 
    }

	return (
		<Wrapper>
            {sigImage ?
                <img alt='signature' src={sigImage} />
            :
                <>
                    <IntroText>Use the white box below to write your signature</IntroText>
                    <CanvasWrapper>
                        <SignatureCanvas penColor='black'
                            canvasProps={{width: 300, height: 150, className: 'sigCanvas'}} 
                            ref={(ref) => { sigPad = ref }}
                            onBegin={ handleBegin }/>
                    </CanvasWrapper>
                </>
            }
            {error &&
                <ErrorText>{error}</ErrorText>
            }
            {!isEmpty &&
                <ButtonWrapper>
                    <Button onClick={ handleClear }>Clear signature</Button> 
                    {sigImage == null &&           
                        <Button submit="true" onClick={ handleSave }>Save signature</Button>
                    }
                </ButtonWrapper>
            }
		</Wrapper>
	)
}
