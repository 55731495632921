import React from "react"

import Favicon from "../../images/logos/gb-icon.png"

export const Meta = () => {

	return (
		<>
			<meta name="robots" content="noindex, nofollow" />
			<link id="icon" rel="icon" href={Favicon} />
		</>
	)
}
