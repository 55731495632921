import React from "react"

import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer/lib/react-pdf.browser.es.js'
import { Date } from "../partials/"

import FrontPageImage from "../../images/pdf-assets/contract-image-01.png"
import CompanyLogo from "../../images/pdf-assets/hs-logo.png"
import CompanySignature from "../../images/pdf-assets/hs-signature.png"

const brown = '#dfd8d1'
const red = '#f00'

const styles = StyleSheet.create({
    page: {
		flexDirection: 'column',
		backgroundColor: '#fff',
		padding: 20,
		fontFamily: 'Helvetica',
        fontSize: 11
	},
    frontPageImage: {
		width: 350,
	}, 
    header: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        flexGrow: 1
    },
    footer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    brandLogo: {
		width: 200,
	}, 
    title: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 35,
        marginBottom: 20
    },
    h1: {
        backgroundColor: brown,
        padding: 10,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        lineHeight: 1.2,
        marginBottom: 20
    },
    h2: {
        backgroundColor: brown,
        padding: 5,
        fontFamily: 'Helvetica-Bold',
        fontSize: 12,
    },
    pageContainer: {
        border: '1px solid #dfd8d1',
        borderColor: brown,
        padding: 50,
        paddingBottom: 0,
        flexGrow: 1
    },
    textContainer: {
        border: '1px solid #dfd8d1',
        padding: 5
    },
    textGroup: {
        marginBottom: 5
    },
    text: {
        lineHeight: 1.15
    },
    textMargin: {
        marginBottom: 5
    },
    textMarginTop: {
        marginTop: 5
    },
    listMargin: {
        marginBottom: 10
    },
    list: {
        flexDirection: "column",
        marginLeft: 10,
        marginRight: 15,
    },
    listItem: {
        flexDirection: "row",
        marginBottom: 2,
        marginRight: 15
    },
    listBullet: {
        marginRight: 5
    },
    attentionText: {
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        lineHeight: 1.2,
        marginBottom: 50
    },
    highlight: {
        color: red
    },
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
    signature: {
		width: 150,
	}, 
    companySignature: {
        width: 200,
    },
    row: {
        flexDirection: "row",
    },
    rowMargin: {
        flexDirection: "row",
        marginBottom: 10
    }
})

export const ClaimContractPDF = ({claimData}) => {

    const IssueList = () => {
        return (
            claimData.issue.map((item, i, listItems) => {
                return (
                    <Text key={i}>
                        {item}{(i + 1) < listItems.length && ', '}
                    </Text>
                )
            })
        )
    }

    const Header = () => {
        return (
			<View style={ styles.header }>
                <Image style={ styles.brandLogo } source={CompanyLogo}/>
            </View>
		)
    }

    const Footer = ({noLogo, page}) => {
        return (
			<View style={ styles.footer }>
                <View>
                    {!noLogo &&
                        <Image style={ styles.brandLogo } source={CompanyLogo}/>
                    }
                </View>
                <Text>Page | {page}</Text>
            </View>
		)
    }

    const Signature = () => {
        return (
			<View style={ styles.textGroup }>
                <View style={ styles.textContainer }>
                    <View style={ styles.rowMargin }>
                        <Text style={ styles.textMargin }>Signed by the Client:</Text>	
                        <Image style={ styles.signature } source={claimData.signature}/>
                    </View>
                    <Text style={ styles.textMarginTop }>Dated: <Text style={ styles.bold }><Date date={claimData.dateClaimSigned}/></Text></Text>	
                </View>	
            </View>
		)
    }

	return (
		 <Document>
            <Page size="A4" style={ styles.page }>
				<View style={ styles.body }>
                    <View style={{  backgroundColor: brown, flexGrow: 1, width: '50%', padding: 30 }}>
                        <View style={ styles.title }>
                            <Text>YOUR</Text>
                            <Text>HOUSING</Text>
                            <Text>DISREPAIR</Text>
                            <Text>CLAIM</Text>
                        </View>
                        <View style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'cemter', justifyContent: 'center' }}>
                            <Image style={ styles.frontPageImage } source={FrontPageImage}/>
                        </View>
                        <Image style={ styles.brandLogo } source={CompanyLogo}/>
                    </View>
                </View>
                <Footer noLogo={true} page="1"/>
			</Page>
			<Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Thank you for instructing us to act on your behalf in this Housing Disrepair Case.</Text>
                    </View>
                    <View style={ styles.pageContainer }>
                        <Text style={ styles.attentionText }>As advised, this case is no-win no-fee and you will not be charged for any of the work we do so long as you remain honest and co-operative throughout.</Text>
                        <Text style={ styles.attentionText }>This booklet contains all information that you will need to confirm you agree to in order for us to proceed with your claim.</Text>
                        <Text style={ styles.attentionText }>If you wish to discuss any of the contents before signing please call our office on <Text style={ styles.highlight }>01744 744 400</Text> and ask for the Housing Disrepair Team.</Text>
                    </View>
                    </View>
                <Footer page="2"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Overview of your Housing Disrepair Case</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 1: Sign up</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>Now we have assessed your case and believe it has a reasonable prospect of success, we will send out some documentation. For us to act on your behalf to recover compensation and get the repairs completed, we require you to sign where prompted on this documentation.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 2: We make contact with your landlord</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>Once we are in receipt of your signed documentation, we contact your Housing Association/Council through a Letter of Claim. This will outline the claim against them. They have 28 days to respond to our letter. During this timescale, your landlord may try to contact you to arrange repairs, we advise for no repairs to be complete yet unless it is an emergency in order to preserve evidence for your claim.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 3: Surveyors Inspections</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>Whilst awaiting response from the landlord we arrange for a qualified surveyor to attend your property to conduct a report of all the repairs to be done in your property. The landlord may also send out their own surveyor to complete a similar survey. Please ensure you allow access to both surveyors. You will always be informed of the date and time prior to inspection and can always change the date if not suitable. We then await the report and provide you with a copy to ensure you are happy that it covers everywhere. Once you confirmed you are happy with the report we move to the next stage where we will value your claim.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 4: Suggested Offer</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>When we have received confirmation that you are happy with the expert surveyor's report, we then provide you with a suggested offer. This is a value which we believe your case is worth. This offer will include monetary compensation as well as getting the repairs completed by the landlord. If you are happy with this, following your consent we will begin negotiations with your landlord.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 5: Negotiations</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>As stated above, we will commence negotiations by making an offer of settlement to your landlord. They have a time scale of 28 days to respond to our offer and may accept, reject, or make a counter offer. We will liaise directly with the landlord to ensure your case is settled out of court. However, in some cases where your landlord will not engage, we may need to issue your claim at court. If this is the case, we will inform you of this in full detail further into your case.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 6: Settlement</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>When we have negotiated an offer you are happy with, this is at the point where your case has come to settlement. This will include for your landlord to complete repairs within a specified period of time along with a sum of compensation for your inconvenience. Once this is received, we then wait for repairs to be complete and payment of compensation.</Text>	
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Stage 7: Enforcement</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>Once settlement has been achieved, we ask that you monitor the repairs being completed by your landlord and keep us updated on the progress. If they do not complete your repairs in the agreed timescale without good reason for failing to do so, we will look to bring enforcement proceedings for breach of contract.</Text>	
                        </View>			
                    </View>
                </View>
                <Footer page="3"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Client Care</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Hours of Business & Communication</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>The normal hours of opening at our offices are between 9am and 5pm on weekdays. Messages can be left on the voicemail outside those hours. Please note that all calls are recorded. We do try to keep paperwork down to a minimum for our clients and it is always at the beginning of a claim that you will be faced with the most, if you need any assistance with this documentation please do not hesitate to call or email us.</Text>	
                            <Text style={[styles.text, styles.textMargin]}>We prefer to correspond via email please send us details of your email address as soon as possible.</Text>
                            <Text style={ styles.text }>We also use SMS texting and invite you to use our online file view system with your personal login details to keep track of your case. This has enhanced features to allow you to upload documents and see what's going on with your case and we will send further information about accessing this in due course.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Scope of our instructions</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>We instructed to advise and assist you in relation to your claim for damages.</Text>	
                            <Text style={ styles.textMargin }>Our instructions extend to:-</Text>
                            <View style={ styles.list }>	
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Notification of your intended claim against the person/s responsible; and their representatives/insurers.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Collating and submitting details and evidence in support of your claim and putting your authorised offer of settlement to the defendant /their representative.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Conducting negotiations on your behalf and, if necessary, issuing Court proceedings on the claim</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Any proceedings you take to enforce a judgment, order or agreement</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Negotiations about and/or court assessment of the costs of your claim.</Text>
                                </View>
                            </View>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Timescales</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>The fact that you have cause for complaint to your landlord and issues with your property does not mean that you are automatically entitled to compensation. It remains the Claimant's obligation to prove that the Defendant was responsible in law. This means that Defendants can, and do, successfully defend claims such as this.</Text>	
                            <Text style={[styles.text, styles.textMargin]}>We will keep you informed of developments, but would advise that the defendants, once they have acknowledged the claim, have a set time to investigate the matter, before they must provide their decision on whether to accept liability.</Text>
                            <Text style={ styles.text }>In a straightforward case, such as when the insurer is known, liability is admitted and the case is smooth it may take around 6 to 12 months for the case to conclude. Claims can take longer if they are more complex, the Defendant refuses to accept liability or there are other factors in your case that prevent us settling swiftly.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Responsibility for Your Claim</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>The person responsible for dealing with your case will be communicated to you when the file I formally opened and we have risk assessed the matter. It is likely that one of our Litigation Executives will have conduct. The Director with final responsibility for work done in this department is Bruce Hatton who will supervise the file.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Funding</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>We have agreed to act on your behalf under the terms of a Conditional Fee Agreement (a 'no win no fee agreement'). The terms of the agreement and an explanatory note can be found on page 5-6 The agreement covers all work done since acceptance of your instructions but does not cover any counter claim against you, nor appeal by you or your opponent.</Text>
                        </View>			
                    </View>
                </View>
                <Footer page="4"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Client Care</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>File View</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>Please see our <Text style={ styles.bold }>Terms of Business</Text> for details of how to view the progress of your claim online.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Service Levels</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>We agree to the following service levels:</Text>
                            <View style={[styles.list, styles.listMargin ]}>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will update you with progress on your matter.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will communicate with you in plain language.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will explain to you the legal work required as your matter progresses.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will update you on the cost of your matter at agreed events.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will update you on whether the likely outcomes still justify the likely costs and risks associated with your matter whenever there is a material change in circumstances.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will update you on the likely timescales for each stage of this matter and any important changes in those estimates.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>We will continue to review whether there are alternative methods by which your matter can be funded or if the risk changes.</Text>
                                </View>
                            </View>
                            <Text style={ styles.text }>You agree to respond to and co-operate with our requests for information, documentation and/or instructions promptly to enable us to protect and advance your interests at all times. If you do not assist us and we cannot progress the case this could bring your retainer to an end.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Queries and Complaints</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>We aim to provide the best service possible for all of our clients. If you have any queries or concerns about any aspect of our work on this matter or about the bill, please contact us. For further information about our complaint's procedure, including the right to complain to the Legal Ombudsman, please refer to our <Text style={ styles.bold }>Terms of Business</Text>.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Fraud Risk - Please be vigilant</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>If money needs to be paid into our client account, we will supply you with our client account details. If, having received the details, you receive an email asking for funds to be sent to a different bank account, please contact us, before transferring any money.</Text>
                            <Text style={[styles.text, styles.textMargin]}>Any request for money to be sent to another account may be from a fraudster who may have hacked into your email account and is trying to steal from you. Please be vigilant and always check.</Text>
                            <Text style={ styles.text }>If you have any concerns that an email or other communication purporting to be from this firm may not be genuine, please let us know.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Right to Cancel</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>If you change your mind and wish to cancel your retainer within 14 days you have the right to do so in writing to the main office address by post or by email to <Text style={ styles.bold }>bruce.hatton@hattonslaw.com</Text>.</Text>
                        </View>			
                    </View>
                </View>
                <Footer page="5"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Conditional Fee Agreement</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={ styles.text }>This agreement is a binding legal contract between you and your solicitors. Before you sign, please read everything carefully. This agreement must be read in conjunction with the document: “CFA: What You Need to Know.” This document is available for you to view online at <Text style={ styles.bold }>www.hattonslaw.com/documents</Text>. A hard copy of the document is available upon request or by your attendance at reception of our St Helens office.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>Agreement date: <Text style={ styles.bold }><Date date={claimData.dateClaimSigned}/></Text></Text>	
                            <Text style={[styles.text, styles.textMargin]}>Between:</Text>
                            <Text style={[styles.text, styles.textMargin]}>We, the solicitors, Hattons Solicitors of Prudential Buildings, 3 Victoria Square, St Helens, Merseyside, WA10 1HQ</Text>
                            <Text style={[styles.text, styles.textMargin]}>And:</Text>
                            <Text style={ styles.text }>You, the Client, <Text style={ styles.bold }>{ claimData.title } { claimData.firstName } { claimData.surname }</Text></Text>	
                        </View>	
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>1</Text>
                                    <View>
                                        <Text style={[styles.text, styles.textMargin]}><Text style={ styles.bold }>What is covered by this agreement</Text></Text>
                                        <View style={ styles.list }>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>1.1</Text>
                                                <Text style={ styles.text }>Your claim for damages against your landlord and/or any other party subsequently found liable for your losses in this Housing Disrepair Case which may include a personal injury claim.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>1.2</Text>
                                                <Text style={ styles.text }>Any proceedings you take to enforce a judgement, order or agreement.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>1.3</Text>
                                                <Text style={ styles.text }>Negotiations about and/or a court assessment of the costs of this claim.</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>2</Text>
                                    <View>
                                        <Text style={[styles.text, styles.textMargin]}><Text style={ styles.bold }>What is not covered by this agreement</Text></Text>
                                        <View style={ styles.list }>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>2.1</Text>
                                                <Text style={ styles.text }>Any counterclaim against you.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>2.2</Text>
                                                <Text style={ styles.text }>Any appeal you make against the final judgement order</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>3</Text>
                                    <View>
                                        <Text style={[styles.text, styles.textMargin]}><Text style={ styles.bold }>Paying us</Text></Text>
                                        <View style={ styles.list }>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.1</Text>
                                                <Text style={ styles.text }>If you win Your claim, you pay our basic charges, our disbursements and a success fee (“Our Costs”).</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.2</Text>
                                                <Text style={ styles.text }>You are entitled to seek recovery from Your opponent of part or all of our basic charges and our disbursements (and any VAT thereon) but not the success fee and any After-the-Event Insurance premium (where applicable).</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.3</Text>
                                                <Text style={ styles.text }>Following the recovery of any contribution to your costs made by your opponent, you will be responsible for any shortfall in our basic charges, disbursements and any VAT thereon together with our success fee and any Afterthe- Event insurance premium (where applicable), net of any sums recoverable by the Compensation Recovery Unit of the Department of Work and Pensions, inclusive of VAT.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.4</Text>
                                                <Text style={ styles.text }>We agree that the total of the success fee and any unrecovered basic charges (including VAT) that you must pay will not exceed 35% of the compensation awarded to you.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.5</Text>
                                                <Text style={ styles.text }>In addition, however, you must also pay any unrecovered disbursements and any ATE insurance premium (if applicable) as set out in the document “CFA: What You Need to Know”.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.6</Text>
                                                <Text style={ styles.text }>It may be that Your opponent makes an offer or payment, which you reject on our advice and Your claim for damages goes ahead to trial where you recover damages that are less than that offer or payment. If this happens, we will not claim any costs for the work done after the last date for acceptance of the offer or payment.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.7</Text>
                                                <Text style={ styles.text }>If you receive interim damages, we may require you to pay our disbursements at that point and a reasonable amount for our future disbursements.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.8</Text>
                                                <Text style={ styles.text }>If you receive provisional damages, we are entitled to payment of our basic charges our disbursements and success fee at that point.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>3.9</Text>
                                                <Text style={ styles.text }>If you lose you will not have to pay Your opponent's costs unless any of the conditions listed in section <Text style={ styles.bold }>Dealing with costs if you Lose</Text> in the document “CFA: What You Need to Know” apply (a copy of which is attached with this CFA). This document is also available for you to view online at <Text style={ styles.bold }>www.hattonslaw.com/documents</Text>.</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>			
                    </View>
                </View>
                <Footer page="6"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Conditional Fee Agreement (continued)</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>4</Text>
                                    <View>
                                        <Text style={[styles.text, styles.textMargin]}><Text style={ styles.bold }>The Success Fee</Text></Text>
                                        <View style={ styles.list }>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.1</Text>
                                                <Text style={ styles.text }>The success fee is set at 100% of our basic charges. This percentage is not set by reference to the risks of your individual case, but is a standard success fee which we apply to all claims of this type where we agree to act on a Conditional Fee Agreement.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.2</Text>
                                                <Text style={ styles.text }>The success fee, together with any unrecovered basic charges (including VAT) will be capped at 35% of the damages you recover relating to;</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.3</Text>
                                                <Text style={ styles.text }>General damages for discomfort and inconvenience, compensation due for the failure to repair; and</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.4</Text>
                                                <Text style={ styles.text }>Damages for pecuniary loss regards building, other than future pecuniary loss.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.5</Text>
                                                <Text style={ styles.text }>For any damages for personal injury, the success fee will be capped at 25%.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>4.6</Text>
                                                <Text style={ styles.text }>None of the success fee relates to the postponement of payment of our fees and expenses. The Success fee (inclusive of any additional percentage relating to postponement) cannot be more than 100% of the basic charges in total.</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>5</Text>
                                    <View>
                                        <Text style={[styles.text, styles.textMargin]}><Text style={ styles.bold }>Other points</Text></Text>
                                        <View style={ styles.list }>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>5.1</Text>
                                                <Text style={ styles.text }>By signing this Agreement, you are agreeing that the standard success fee which we charge in all claims of this type is a reasonable success fee and by doing so this may limit your ability to subsequently challenge that success fee by way of assessment.</Text>
                                            </View>
                                            <View style={ styles.listItem }>
                                                <Text style={ styles.listBullet }>5.2</Text>
                                                <Text style={ styles.text }>Furthermore, by signing this agreement you agree and acknowledge that certain items of costs, such as the Success Fee, unrecovered basic charges and unrecovered disbursements remain payable by you even though they may not be recovered at all from your opponent and you confirm your express agreement to the hourly rates and the method by which our basic charges are calculated as set out in this agreement, the CFA Letter and the CFA Schedule.</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <View style={ styles.row }>
                                <Text style={[styles.text, styles.textMargin]}>Signed by the solicitor(s):</Text>	
                                <Image style={ styles.companySignature } source={CompanySignature}/>
                            </View>
                        </View>	
                    </View>
                    <Signature/>
                </View>
                <Footer page="7"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Acceptance of CFA Terms and Terms and Conditions of Business</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>I, <Text style={ styles.bold }>{ claimData.title } { claimData.firstName } { claimData.surname }</Text> confirm that I have read, understood and accept the terms set out in Hattons Solicitors Letters of Engagement and the
Law Society Conditions (as amended) forming the <Text style={ styles.bold }>Conditional Fee Agreement (CFA)</Text> and have read, understood
and accept Hattons Solicitors <Text style={ styles.bold }>Terms and Conditions of Business</Text>.</Text>	
                            <View style={ styles.list }>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>In particular, I understand and accept:</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>The hourly rates set out in the CFA may be higher than those offered by some firms but that these are the hourly rates Hattons Solicitors charge and I agree them in the knowledge that other firms may charge lower hourly rates;</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>That I am responsible for paying the Basic Charges, Disbursements and any VAT thereon of Hattons Solicitors for the work they do on my claim and, whilst Hattons Solicitors will attempt to recover them from my opponent in my claim, if it is successful, I am responsible for the shortfall of any Basic Charges, Disbursements and VAT thereon not recovered from my opponent in this claim.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>That I am, in addition, responsible for a Success Fee which is fixed at 100% of the Basic Charges (where the percentage is not a reflection of the risks of my claim succeeding or failing) but which is, together with the Basic Charges (and any VAT), Capped at 35% of my compensation, after deducting any costs recovered from my opponent in this claim.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>That I am responsible for any unrecovered Disbursements and, if applicable, any After-The-Event (ATE) premium in addition to the Cap.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>That I am agreeable to have an invoice for legal costs delivered to me by email where I have provided Hattons Solicitors with an email address.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>That I agree to be bound by the <Text style={ styles.bold }>Terms of Business</Text> I have been sent by Hattons Solicitors and authorise them to progress the case accordingly by signing this form.</Text>
                                </View>
                            </View>
                        </View>	
                    </View>
                    <Signature/>
                </View>
                <Footer page="8"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>After the Event Insurance</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>As you are aware, we are acting for you under a Conditional Fee Agreement (no win, no fee).</Text>	
                            <Text style={[styles.text, styles.textMargin]}>Whilst you may get some protection against paying our opponents costs by the concept of Qualified One Way Costs Shifting (QOCS) this is not always the case and whilst the no win, no fee agreement covers the costs for our time it does not cover the expenses we incur called disbursements (e.g. the cost of surveyors). That means that in the event that you lose the case you would have to pay these expenses under the terms and conditions we act under.</Text>
                            <Text style={[styles.text, styles.textMargin]}>To avoid that risk of meeting these costs yourself, you would have to take out a policy of insurance that covers these disbursements. If you win the case, you pay for the premium out of damages but if you lose you do not pay anything as the policy covers your disbursements and the policy premium is waived.</Text>
                            <Text style={ styles.text }>The premium you will pay on this insurance will vary depending on insurers but estimated to be between £395.00 and £500.00. All premiums will be subject to IPT which is payable at the prevailing rate when a case concludes successfully.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>Why do I need Insurance Cover?</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>In the event that we are unable to recover all of the disbursements reasonably incurred on your behalf, you will be responsible for reimbursing Hattons Solicitors for the costs incurred. This would also apply to any of the Defendant's costs that you may be ordered to pay by a court, unless this obligation arises through any negligent act or omission on the part of Hattons Solicitors.</Text>	
                            <Text style={ styles.text }>You should check to see if you have any Before the Event insurance that may provide you with cover e.g. attached to household insurance or through a Trade Union membership however you do not have any alternative means meeting such costs and do not want to pay them on account of costs as you go along we believe that After the Event Insurance is appropriate. The purpose of this insurance is to meet the costs of any unrecovered disbursements which we incur on your behalf and also to meet any element of the Defendant's costs that you may be ordered to pay. Hattons Solicitors are not authorised by the Financial Conduct Authority. However, we are included on the register maintained by the Financial Conduct Authority so that we can carry on insurance distribution activity, which is broadly the advising on, selling and administration of insurance contracts. This part of our business, including arrangements for complaints or redress if something goes wrong, is regulated by the Solicitors Regulation Authority.</Text>
                        </View>			
                    </View>
                    <View style={ styles.textGroup }>
                        <Text style={ styles.h2 }>The Insurance Premium</Text>	
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>The premium that will be payable usually secures cover of up to £10,000 in your claim will be confirmed once we have received the signed authority from you and we have applied for cover.</Text>	
                            <Text style={[styles.text, styles.textMargin]}>The premium is recoverable at the conclusion of the claim assuming that it is successful. It will be deducted from your damages and paid directly to the insurers , in accordance with the form of authority enclosed with this letter.</Text>
                            <Text style={[styles.text, styles.textMargin]}>In the event that you do not recover compensation, the premium is covered by the policy itself. As a consequence, no payment will be due from you up front for the policy premium.</Text>
                            <Text style={ styles.text }>We would be obliged if you would kindly sign and return the below to us if you wish to take out this policy.</Text>
                        </View>			
                    </View>
                </View>
                <Footer page="9"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>After the Event Insurance (continued)</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>I, <Text style={ styles.bold }>{ claimData.title } { claimData.firstName } { claimData.surname }</Text> hereby confirm, authorise and consent as follows: I have been informed of the
reasons why the above insurance option is available and a useful option to take to compliment the Conditional Fee Agreement I have
with Hattons Solicitors and I confirm as Follows:</Text>	
                            <View style={ styles.list }>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>I have no Before the Event Policy for legal expenses available to me. And do not wish to pay privately or the disbursements as we go along.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>In the event of a loss of my legal case the ATE policy will cover the reasonable costs & outlays of the Defendants.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>It will also cover the disbursements of my own Solicitors.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>I am aware of the estimated premium for the said Insurance policy.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Upon the success of my claim for damages whether by negotiated settlement or by Court hearing, the appropriate premium (as stated in the schedule to be received), will be deducted from the damages I am awarded by my Solicitors and paid to the ATE provider.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>I consent to the deduction and understand that the ATE premium is not recoverable from the Defendants.</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>•</Text>
                                    <Text style={ styles.text }>Accordingly, I hereby authorise Hattons Solicitors to prepare the proposal form, and all relevant documents to the ATE provider for consideration of a policy to be issued which I understand am responsible for the cost of and authorise them to deduct from damages.</Text>
                                </View>
                            </View>
                        </View>	
                    </View>
                    <Signature/>
                </View>
                <Footer page="10"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Form of Authority</Text>
                    </View>
                    <Header/>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <Text style={[styles.text, styles.textMargin]}>I, <Text style={ styles.bold }>{ claimData.title } { claimData.firstName } { claimData.surname }</Text> authorise my Landlord to release all relevant documents requested by Hattons Solicitors including, but not limited to:-</Text>	
                            <View style={ styles.list }>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>Any written agreements between the Respondent and the Applicant including, the Tenancy Agreement,</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>All documents including electronic documents or computerised records relating to notice given of the disrepairs,</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>All documents including electronic documents or computerised records relating to the disrepairs reported,</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>All documents including electronic documents or computerised records relating inspection records,</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>All documents including electronic documents or computerised records relating to repair works to the property,</Text>
                                </View>
                                <View style={ styles.listItem }>
                                    <Text style={ styles.listBullet }>(i)</Text>
                                    <Text style={ styles.text }>Any other documents including electronic documents in the tenancy file.</Text>
                                </View>
                            </View>
                        </View>	
                    </View>
                    <Signature/>
                </View>
                <Footer page="11"/>
			</Page>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Claim summary</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Name</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.title } { claimData.firstName } { claimData.surname }</Text>	
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Email Address</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.emailAddress }</Text>
                            </View>	
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Phone Number</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.phoneNumber }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Address</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.address }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>What issues do you have?</Text>
                                <Text style={[styles.text, styles.textMargin]}><IssueList/></Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Who do you rent from?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.rentalType }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Have you contacted the landlord?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.contactLandlord }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>When did you report it?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.whenReported }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Has the landlord fixed the issues?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.landlordFixed }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Are you currently in arrears?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.inArrears }</Text>
                            </View>
                        </View>	
                    </View>
                </View>
                <Footer page="12"/>
			</Page>
		</Document>
	)
}
