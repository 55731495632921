import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

//redux
import { useDispatch } from 'react-redux'
import { updateClaimId, updateClaimData } from '../store/reducers/claimSlice';

//firebase
import { auth, db } from '../utils/firestore';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

//components
import { Loading } from "../special/"

import { Header, Footer } from "./"

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`
const Main = styled.main`
	flex-grow: 1;
`

export const Page = ({ children, editClaimCallback }) => {

	const [loading, setLoading] = useState(true)

	const dispatch = useDispatch();
	const getClaim = async (id) => {
		const docSnap = await getDoc(doc(db, "claims", id)); 
		dispatch(updateClaimId(id))
		if (docSnap.exists()) {
			dispatch(updateClaimData(docSnap.data()))
			editClaimCallback(false)
		} else {
			editClaimCallback(true)
		}
		setLoading(false)
	}

	const checkLinkSignIn = () => {
		
			if (isSignInWithEmailLink(auth, window.location.href)) {
				console.log('try sign in')
				setLoading(true)
				let email = window.localStorage.getItem('emailForSignIn');
				if (!email) {
					email = window.prompt('Please provide your email for confirmation');
				}
				signInWithEmailLink(auth, email, window.location.href)
				.then((result) => {
					getClaim(email)
					window.localStorage.removeItem('emailForSignIn');
				})
				.catch((error) => {
					setLoading(false)
					console.log(error.code)
					if(error.code === 'auth/invalid-action-code') {
						alert('This secure link has already been used and is no longer valid. Please request a new one using the form.')
					} else if(error.code === 'auth/missing-email') {
						alert("We are unable to allow access without verifying your email address")
					} else if(error.code === 'auth/invalid-email') {
						alert("The email address you provided does not match the one associated with this secure link.")
					} else {
						alert(error.message)
					}
				});
			} else {
				setLoading(false)
			}
	
	}

	useEffect(() => {
		checkLinkSignIn()
    }, [])

	return (
			<>
				{loading ?
					<Loading/>
				:
					<Container>
						<Header/>
						<Main>
							{ children }
						</Main>
						<Footer/>
					</Container>
				}
			</>
	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
