import React, { useEffect, useState } from "react"
import styled from 'styled-components'

import { db } from '../utils/firestore';
import { collection, getCountFromServer, query, getDocs, where, orderBy } from "firebase/firestore"; 

import { Date, DatePickerField } from '../partials/';
import { BreakPoint, FontSize, Green, GreyDark } from "../variables/"
import { Component } from "../styles/"
import { DownloadClaim, Loading } from './';

const TabWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid ${Green.primary};
    margin-bottom: 20px;
`
const Tab = styled.div`
    ${FontSize.md}
    font-weight: bold;
    padding: 10px 15px;
    margin-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    &.active, &:hover {
        background: ${Green.primary};
        color: ${Green.secondary};
    }
`
const Total = styled.h2`
    ${FontSize.lg}
    margin-bottom: 15px;
`
const ListGroup = styled.div`
    margin-bottom: 15px;
`
const ListHeader = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: ${GreyDark.primary};
    color: ${GreyDark.secondary};
    > h2 {
        ${FontSize.md}
        margin-bottom: 10px;
        @media ${BreakPoint.md} { 
            margin-bottom: 0px;
        }
    }
`
const ListItem = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -10px;
    ${FontSize.sm}
    @media ${BreakPoint.md} { 
        flex-direction: row;
        > div {
            &:last-child {
                text-align: right;
            }
        }
    } 
    strong {
        font-weight: bold;
    }
`
const ItemCol = styled.div`
    margin-bottom: 10px;
    p {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const ClaimList = ({defaultStartDate, defaultEndDate}) => {

    const [loading, setLoading] = useState(true);
    const [claimTotal, setClaimTotal] = useState(null);
    const [claims, setClaims] = useState(null);
    const [claimType, setClaimType] = useState('');

    const [claimStartDate, setClaimStartDate] = useState(defaultStartDate);
    const [claimEndDate, setClaimEndDate] = useState(defaultEndDate);

    const dateChange = (dates) => {
        const [start, end] = dates
        setClaimStartDate(start)
        setClaimEndDate(end)
        if(start && end) {
            getClaims(claimType, start, (new window.Date(end.setUTCHours(23, 59, 59, 999))))
        }
    };

    const getClaims = async (type, start, end) => {
        setLoading(true)
        if(type === "signed") {
            const allSignedClaims = query(collection(db, "claims"), where('dateClaimSigned', '!=', false));
            const snapshot = await getCountFromServer(allSignedClaims);
            setClaimTotal(snapshot.data().count)

            const q = query(
                collection(db, "claims"), 
                where('dateClaimSigned', '!=', false),
                where('dateClaimSigned', '>=', (start ?? claimStartDate)),
                where('dateClaimSigned', '<=', (end ?? claimEndDate)),
                orderBy("dateClaimSigned", "desc")
            );
            const claimsSnapshot = await getDocs(q);
            if (claimsSnapshot) {
                const claimsList = claimsSnapshot.docs.map((doc) => doc.data());
                setClaims(claimsList)
                setClaimType("signed")
                setLoading(false)
            }
        } else if(type === "started") {
            const q = query(
                collection(db, "claims"), 
                where('signature', '==', '')
            );
            const claimsSnapshot = await getDocs(q);
            if (claimsSnapshot) {
                const claimsList = claimsSnapshot.docs.map((doc) => doc.data());
                const orderClaims = [...claimsList].sort((a, b) => a.dateClaimStarted - b.dateClaimStarted);
                setClaims(orderClaims)
                setClaimType("started")
                setLoading(false)
            }
        } else {
            setClaims(null)
            setLoading(false)
        }
        
    }

    useEffect(() => {
        getClaims("signed")
    },[])

    const Claimant = ({claimData, signed}) => {
        return (
			<ListItem>
                <ItemCol>
                    <p><strong>{claimData.title} {claimData.firstName} {claimData.surname}</strong></p>
                    <p>{claimData.phoneNumber}</p>
                    <p>{claimData.emailAddress}</p>
                </ItemCol>
                <ItemCol>
                    {claimData.dateClaimSigned ?
                        <>
                            <p><strong>Date signed:</strong> <Date date={claimData.dateClaimSigned}/></p>
                            <p><strong>Submitted:</strong> {claimData.dateClaimSubmitted ? 'Yes' : 'No'}</p>
                            <DownloadClaim claimData={claimData} summary={claimData.dateClaimSubmitted ? false : true} isLink="true"/>
                        </>
                    :
                        <>
                            <p><strong>Date started:</strong> <Date date={claimData.dateClaimStarted}/></p>
                            <DownloadClaim claimData={claimData} summary={true} isLink="true"/>
                        </>
                    }
                </ItemCol>
            </ListItem>
		)
    }

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="800px">
                    <TabWrapper>
                        <Tab onClick={() => getClaims("signed")} className={claimType === "signed" && "active"}>Signed</Tab>
                        <Tab onClick={() => getClaims("started")} className={claimType === "started" && "active"}>Started</Tab>
                    </TabWrapper>
                    {claims &&
                        <>
                            {claimType === 'signed' && claimTotal &&
                                <Total>Total signed claims: {claimTotal}</Total>
                            }
                            <ListHeader>
                                <h2>{claims.length} {claimType} claim{claims.length > 1 && 's'} {claimType === 'signed' && 'between'}</h2>
                                {claimType === 'signed' &&
                                    <DatePickerField 
                                        startDate={claimStartDate}
                                        endDate={claimEndDate}
                                        onChange={(dates) => dateChange(dates)}/>
                                } 
                            </ListHeader>
                            {claims.length > 0 &&
                                <ListGroup>
                                    { claims.map((claim, i) => {
                                        return(
                                            <div key={ i }>
                                                <Claimant claimData={claim}/>
                                            </div>
                                        )
                                    })}
                                </ListGroup>
                            }
                        </>
                        
                    }
                </Component>
            }
        </>
	)
}
