import React, { useState, useEffect } from "react"
import { useDispatch } from 'react-redux';
import { updateClaimId, updateClaimData } from '../store/reducers/claimSlice';

import { db } from '../utils/firestore';
import { doc, getDoc, setDoc } from "firebase/firestore"; 

import { AddressField, InputField, CheckField, SelectField } from "../partials/"
import { Button, ButtonWrapper, Component, ErrorText, TextGroup } from "../styles/"
import { Loading } from "./"

export const ClaimForm = ({emailAddress, claimData, editClaimCallback}) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [claimExists, setClaimExists] = useState(false);
    const [qualifyComplete, setQualifyComplete] = useState(false);
    const [detailsComplete, setDetailsComplete] = useState(false);
    const [inArrears, setInArrears] = useState(false);
    const [landlordFixed, setLandlordFixed] = useState(false);

    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);

	const [formValues, setFormValues] = useState({
        title: claimData ? claimData.title : '',
		firstName: claimData ? claimData.firstName : '',
        surname: claimData ? claimData.surname : '',
		emailAddress: claimData ? claimData.emailAddress : '',
        phoneNumber: claimData ? claimData.phoneNumber : '',
        address: claimData ? claimData.address : '',
		issue: claimData ? claimData.issue : [],
        rentalType: claimData ? claimData.rentalType : '',
        contactLandlord: claimData ? claimData.contactLandlord : '',
        whenReported: claimData ? claimData.whenReported : '',
        landlordFixed: claimData ? claimData.landlordFixed : '',
        inArrears: claimData ? claimData.inArrears : '',
        dateClaimStarted: claimData ? (claimData.dateClaimStarted ? claimData.dateClaimStarted : new Date()) : new Date(),
        signature: ''
	})

    const [titleOptions] = useState([
		{ value: 'Mr', label: 'Mr'},
		{ value: 'Mrs', label: 'Mrs'},
        { value: 'Ms', label: 'Ms'},
        { value: 'Miss', label: 'Miss'},
	])

    const [issueOptions] = useState([
		{ value: 'Damp', label: 'Damp'},
		{ value: 'Mould', label: 'Mould'},
        { value: 'Windows', label: 'Windows'},
        { value: 'Doors', label: 'Doors'},
        { value: 'Structural', label: 'Structural'},
        { value: 'Water leaks', label: 'Water leaks'},
        { value: 'Heating', label: 'Heating'},
        { value: 'Electrical', label: 'Electrical'},
        { value: 'Other', label: 'Other'},
	])

    const [rentOptions] = useState([
		{ value: 'Legal Council', label: 'Legal Council'},
		{ value: 'Housing Association', label: 'Housing Association'},
        { value: 'Private Landlord', label: 'Private Landlord'},
	])

    const [booleanOptions] = useState([
		{ value: 'Yes', label: 'Yes'},
		{ value: 'No', label: 'No'},
	])

    const [whenReportedOptions] = useState([
		{ value: '0-3 months', label: '0-3 months'},
		{ value: '3-6 months', label: '3-6 months'},
        { value: '6 months+', label: '6 months+'},
	])

	function handleInputChange(e) {
		e.persist();
		setFormValues(currentValues => ({
			...currentValues,
			[e.target.name]: e.target.value
		}))
	}

    function handleCheckChange(e) {
		e.persist();
        let propertyValue = formValues[e.target.name]
        const value = e.target.value
        if(propertyValue) {
            if(propertyValue.includes(value)) {
                const valueIndex = propertyValue.indexOf(value);
                propertyValue.splice(valueIndex, 1);
            } else {
                propertyValue = [...propertyValue, value]
            }
            setFormValues(currentValues => ({
                ...currentValues,
                [e.target.name]: propertyValue
            }))
        }
	}

    function checkPhoneNumber() {
		const regex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i;
        if(!regex.test(formValues.phoneNumber)) {
			setErrorPhoneNumber('Please enter a valid phone number')
		} else {
			setErrorPhoneNumber(null)
            setLoading(true)
            checkClaim()
		}
	}

    const checkClaim = async () => {
        const docSnap = await getDoc(doc(db, "claims", formValues.emailAddress)); 
		if (docSnap.exists() && docSnap.data() && docSnap.data().dateClaimSubmitted) {
            setClaimExists(true)
            setLoading(false)
		} else {
			addClaim(formValues.emailAddress, formValues)
		}
    }

    const addClaim = async (id, data) => {
        await setDoc(doc(db, "claims", id), data)
        dispatch(updateClaimId(id))
        dispatch(updateClaimData(data))
        editClaimCallback(false)
        setLoading(false)
    }

    const handleBack = (evt) => {
		evt.preventDefault()
        if(!detailsComplete) {
            setQualifyComplete(false)
        } else {
            setDetailsComplete(false)
        }
        
	}

    const handleSubmit = (evt) => {
		evt.preventDefault()
        if(!qualifyComplete) {
            if(formValues.inArrears === 'Yes' || formValues.landlordFixed === 'Yes') {
                setLandlordFixed(formValues.landlordFixed === 'Yes' ? true : false);
                setInArrears(formValues.inArrears === 'Yes' ? true : false);
            } else {
                setQualifyComplete(true)
            } 
        } else if(!detailsComplete) {
            setDetailsComplete(true)
        } else {
            checkPhoneNumber()
        }    
	}

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="800px">
                    {claimExists ?
                        <TextGroup>
                            <h1>Claim already exists</h1>
                            <p>A claim for {formValues.emailAddress} has previously been submitted.</p>
                        </TextGroup>
                    :
                        <>
                            <TextGroup>
                                {(inArrears || landlordFixed) ?
                                    <>
                                        <h1>Unfortunately your claim is not valid due to the following:</h1>
                                        <ul>
                                            {landlordFixed &&
                                                <li>Your landlord has already fixed the issue</li>
                                            }
                                            {inArrears &&
                                                <li>You are currently in arrears</li>
                                            }
                                        </ul>
                                    </>
                                    
                                :   
                                    !qualifyComplete ?
                                        <>
                                            <h1>Start your claim today</h1>
                                            <p>Are you eligable to make a claim? Please answer the questions below.</p>  
                                        </>
                                    : !detailsComplete ?
                                        <>
                                            <h1>Your claim</h1>
                                            <p>Please answer the questions below.</p>
                                        </>   
                                    :
                                        <>
                                            <h1>Your claim</h1>
                                            <p>Please enter your contact details below.</p>
                                        </>
                                }
                            </TextGroup>
                            {(!inArrears && !landlordFixed) &&
                                <form onSubmit={ handleSubmit }>
                                    {!qualifyComplete ?
                                        <>
                                            <CheckField
                                                label="Has the landlord fixed the issues?"
                                                dataName="landlordFixed"
                                                name="landlordFixed"
                                                value={ formValues.landlordFixed }
                                                onChange={ handleInputChange }
                                                options={ booleanOptions }
                                                type="radio"
                                                required/>
                                            <CheckField
                                                label="Are you currently in arrears?"
                                                dataName="inArrears"
                                                name="inArrears"
                                                value={ formValues.inArrears }
                                                onChange={ handleInputChange }
                                                options={ booleanOptions }
                                                type="radio"
                                                required/>
                                        </>
                                    : !detailsComplete ?
                                        <>
                                            <CheckField
                                                label="What issues do you have?"
                                                dataName="issue"
                                                name="issue"
                                                value={ formValues.issue }
                                                onChange={ handleCheckChange }
                                                options={ issueOptions }
                                                type="checkbox"
                                                required/>         
                                            <CheckField
                                                label="Who do you rent from?"
                                                dataName="rentalType"
                                                name="rentalType"
                                                value={ formValues.rentalType }
                                                onChange={ handleInputChange }
                                                options={ rentOptions }
                                                type="radio"
                                                required/>    
                                            <CheckField
                                                label="Have you contacted the landlord?"
                                                dataName="contactLandlord"
                                                name="contactLandlord"
                                                value={ formValues.contactLandlord }
                                                onChange={ handleInputChange }
                                                options={ booleanOptions }
                                                type="radio"
                                                required/>    
                                            <CheckField
                                                label="When did you report it?"
                                                dataName="whenReported"
                                                name="whenReported"
                                                value={ formValues.whenReported }
                                                onChange={ handleInputChange }
                                                options={ whenReportedOptions }
                                                type="radio"
                                                required/>    
                                        </>
                                    :
                                        <>
                                            <SelectField
                                                label="Title"
                                                name="title"
                                                value={ formValues.title }
                                                setValue={ setFormValues }
                                                options={ titleOptions }
                                                short={ true }
                                                required/>
                                            <InputField
                                                type="text"
                                                label="First Name"
                                                name="firstName"
                                                value={ formValues.firstName }
                                                setValue={ setFormValues }
                                                autocomplete="on"
                                                required/>
                                            <InputField
                                                type="text"
                                                label="Surname"
                                                name="surname"
                                                value={ formValues.surname }
                                                setValue={ setFormValues }
                                                autocomplete="on"
                                                required/>
                                            <InputField
                                                type="email"
                                                label="Email Address"
                                                name="emailAddress"
                                                value={ formValues.emailAddress }
                                                setValue={ setFormValues }
                                                autocomplete="on"
                                                required/>
                                            <InputField
                                                type="tel"
                                                label="Phone Number"
                                                name="phoneNumber"
                                                value={ formValues.phoneNumber }
                                                setValue={ setFormValues }
                                                autocomplete="on"
                                                required/>
                                            {errorPhoneNumber &&
                                                <ErrorText>{errorPhoneNumber}</ErrorText>
                                            }
                                            <AddressField 
                                                label="Address"
                                                name="address"
                                                value={ formValues.address }
                                                setValue={ setFormValues }
                                                required/>
                                        </>
                                    }
                                    <ButtonWrapper>
                                        {qualifyComplete &&
                                            <Button onClick={handleBack}>Back</Button>
                                        }
                                        <Button submit="true" type="submit">Next</Button>
                                    </ButtonWrapper> 
                                </form>
                            }
                        </>
                    }
                </Component>
            }
        </>
	)
}
