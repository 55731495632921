import React from "react"

import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer/lib/react-pdf.browser.es.js'

import CompanyLogo from "../../images/pdf-assets/hs-logo.png"

const brown = '#dfd8d1'

const styles = StyleSheet.create({
    page: {
		flexDirection: 'column',
		backgroundColor: '#fff',
		padding: 20,
		fontFamily: 'Helvetica',
        fontSize: 11
	},
    body: {
        flexGrow: 1
    },
    footer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    brandLogo: {
		width: 200,
	}, 
    h1: {
        backgroundColor: brown,
        padding: 10,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        fontSize: 22,
        lineHeight: 1.2,
        marginBottom: 20
    },
    textContainer: {
        border: '1px solid #dfd8d1',
        padding: 5
    },
    textGroup: {
        marginBottom: 5
    },
    text: {
        lineHeight: 1.15
    },
    textMargin: {
        marginBottom: 5
    },
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
})

export const ClaimSummaryPDF = ({claimData}) => {

    const IssueList = () => {
        return (
            claimData.issue.map((item, i, listItems) => {
                return (
                    <Text key={i}>
                        {item}{(i + 1) < listItems.length && ', '}
                    </Text>
                )
            })
        )
    }

    const Footer = ({noLogo, page}) => {
        return (
			<View style={ styles.footer }>
                <View>
                    {!noLogo &&
                        <Image style={ styles.brandLogo } source={CompanyLogo}/>
                    }
                </View>
                <Text>Page | {page}</Text>
            </View>
		)
    }

	return (
		 <Document>
            <Page size="A4" style={ styles.page }>
                <View style={ styles.body }>
                    <View>
                        <Text style={ styles.h1 }>Claim summary</Text>
                    </View>
                    <View style={ styles.textGroup }>
                        <View style={ styles.textContainer }>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Name</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.title } { claimData.firstName } { claimData.surname }</Text>	
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Email Address</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.emailAddress }</Text>
                            </View>	
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Phone Number</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.phoneNumber }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Address</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.address }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>What issues do you have?</Text>
                                <Text style={[styles.text, styles.textMargin]}><IssueList/></Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Who do you rent from?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.rentalType }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Have you contacted the landlord?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.contactLandlord }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>When did you report it?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.whenReported }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Has the landlord fixed the issues?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.landlordFixed }</Text>
                            </View>
                            <View style={ styles.textMargin }>
                                <Text style={[styles.text, styles.textMargin, styles.bold]}>Are you currently in arrears?</Text>
                                <Text style={[styles.text, styles.textMargin]}>{ claimData.inArrears }</Text>
                            </View>
                        </View>	
                    </View>
                </View>
                <Footer page="1"/>
			</Page>
		</Document>
	)
}
