import React, { useState, useEffect } from "react"
import { useDispatch } from 'react-redux';
import { updateClaimData } from '../store/reducers/claimSlice';
import styled from 'styled-components'

import { db } from '../utils/firestore';
import { doc, setDoc } from "firebase/firestore"; 

import { Button, ButtonWrapper, Component, Group, TextGroup } from "../styles/"
import { FontSize } from "../variables/"
import { SignatureField, List } from "../partials/"
import { DownloadClaim, Loading, ViewClaim, WhatsAppLink } from "./"

const Label = styled.p`
    font-weight: bold!important;
    margin-bottom: 5px!important;
`
const ErrorText = styled.div`
    margin: 40px 0 20px;
    ${FontSize.sm}
`

export const ClaimSign = ({ claimId, claimData, editClaimCallback }) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [signature, setSignature] = useState(null)
    const [error, setError] = useState(null)

    const addClaimSignature = async () => {
        await setDoc(doc(db, "claims", claimId), {
            dateClaimSigned: new Date(),
            signature: signature,
        }, { merge: true })
        dispatch(updateClaimData({
            ...claimData,
            signature: signature
        }))
        setLoading(false)
    }

    const sendMessage = async (claimData) => {
        await setDoc(doc(db, "mail", claimData.emailAddress), {
            from: 'noreply@safedox.co.uk',
            to: process.env.GATSBY_ADMIN_EMAIL,
            template: {
                name: "claim_submitted",
                data: {
                    title: claimData.title,
                    firstName: claimData.firstName,
                    surname: claimData.surname,
                    emailAddress: claimData.emailAddress,
                    phoneNumber: claimData.phoneNumber,
					address: claimData.address,
					issue: claimData.issue,
					rentalType: claimData.rentalType,
					contactLandlord: claimData.contactLandlord,
					whenReported: claimData.whenReported,
					landlordFixed: claimData.landlordFixed,
					inArrears: claimData.inArrears,
                }
            }
        });
    }

    const submitClaim = async () => {
        const dateSubmitted = new Date()
        await setDoc(doc(db, "claims", claimId), {
            dateClaimSubmitted: dateSubmitted,
        }, { merge: true }).then(() => {
            sendMessage(claimData)
            dispatch(updateClaimData({
                ...claimData,
                dateClaimSubmitted: dateSubmitted
            }))
            setLoading(false)
            window.scrollTo(0, 0)
        });
    }

    const handleNext = (evt) => {
		evt.preventDefault()
        if(signature) {
            setLoading(true)
            addClaimSignature()
            setError(null)
        } else {
            setError('Please add and save your signature before submitting your claim')
        }  
	}

    const handleSubmit = (evt) => {
		evt.preventDefault()
        submitClaim() 
	}

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(claimData)
    },[])

	return (
        <>
            {loading ?
                <Loading/>
            :
                <Component maxWidth="800px">
                    <TextGroup>
                        <h1>Your claim</h1>
                        {claimData.signature ?
                            claimData.dateClaimSubmitted ?
                                <>
                                    <p>Your claim has been submitted.</p>
                                    {process.env.GATSBY_WHATSAPP_URL ?
                                        <WhatsAppLink/>
                                    :
                                        <p>A member of our team will be in touch shortly.</p>
                                    } 
                                </>
                            :
                                <>
                                    <p>Your claim is ready to be submitted.</p>
                                    <p>Please review the contract document below before submitting your claim.</p>
                                </>
                        :
                            <>
                                <p>Please check your claim summary below.</p>
                                <p>Once you're happy, continue by signing below and click Next.</p>
                            </>
                        }
                    </TextGroup>
                    { claimData &&
                        !claimData.signature ?
                            <>
                                <TextGroup> 
                                    <Label>Name</Label>
                                    <p>{ claimData.title } { claimData.firstName } { claimData.surname }</p>

                                    <Label>Email Address</Label>
                                    <p>{ claimData.emailAddress }</p>

                                    <Label>Phone Number</Label>
                                    <p>{ claimData.phoneNumber }</p>

                                    <Label>Address</Label>
                                    <p>{ claimData.address }</p>

                                    <Label>What issues do you have?</Label>
                                    <p><List list={claimData.issue}/></p>

                                    <Label>Who do you rent from?</Label>
                                    <p>{ claimData.rentalType }</p>

                                    <Label>Have you contacted the landlord?</Label>
                                    <p>{ claimData.contactLandlord }</p>

                                    <Label>When did you report it?</Label>
                                    <p>{ claimData.whenReported }</p>

                                    <Label>Has the landlord fixed the issues?</Label>
                                    <p>{ claimData.landlordFixed }</p>

                                    <Label>Are you currently in arrears?</Label>
                                    <p>{ claimData.inArrears }</p>

                                    <Label>Signature</Label>
                                    {claimData.signature ?
                                        <img alt='signature' src={claimData.signature} />
                                    :  
                                        <SignatureField sigImage={ signature } setSigImage={ setSignature } errorCallback={ setError }/>
                                    }
                                    
                                </TextGroup>  
                                {claimData.signature ?
                                    <DownloadClaim claimData={ claimData }/>
                                :
                                    <>
                                        {error &&
                                            <ErrorText>{error}</ErrorText>
                                        }
                                        <ButtonWrapper>
                                            <Button onClick={() => editClaimCallback(true)}>Edit claim</Button> 
                                            <Button submit="true" onClick={ handleNext }>Next</Button> 
                                        </ButtonWrapper> 
                                    </>
                                }
                            </> 
                        : 
                            <>
                                <Group>
                                    <DownloadClaim claimData={ claimData } text={!claimData.dateClaimSubmitted ? "Download contract to review" : null}/>
                                </Group>
                                <Group>
                                    <ViewClaim claimData={ claimData }/>
                                </Group>
                                {!claimData.dateClaimSubmitted &&
                                    <>
                                        <TextGroup>
                                            <p>If you're happy with the contract please submit your claim.</p>
                                        </TextGroup>
                                        <ButtonWrapper>
                                            <Button onClick={() => editClaimCallback(true)}>Edit claim</Button>  
                                            <Button submit="true" onClick={ handleSubmit }>Submit claim</Button> 
                                        </ButtonWrapper> 
                                    </>
                                } 
                            </>       
                    }
                </Component>
            }
        </>
	)
}
