import styled from 'styled-components'

import { FontSize, Blue } from "../variables"

export const Field = styled.div`
	position: relative;
	width: ${props => props.short ? "auto" : "100%"};
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 10px;
	}
	p, label, input, select textarea {
		${FontSize.sm}
	}
	input, select, textarea {
		cursor: pointer;
		text-align: ${props => props.align ? props.align : "left"};
		width: ${props => props.short ? "auto" : "100%"};
		display: flex;
		outline: none;
		border: none;
		padding:${props => props.bgColour ? "10px" : "10px 0"};
		border-bottom: 1px solid;
		border-color: ${Blue.primary};
		background: ${props => props.bgColour ? props.bgColour.primary : "transparent"};
		color: ${props => props.bgColour ? props.bgColour.secondary : "inherit"};
		font-family: inherit;
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
		  -webkit-text-fill-color: inherit;
		  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
		  transition: background-color 5000s ease-in-out 0s;
	}
`
