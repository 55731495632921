import React from "react"

import { signOut } from 'firebase/auth';
import { auth } from '../utils/firestore';

import { useDispatch } from 'react-redux';
import { updateAdminUser } from '../store/reducers/adminUserSlice';

import { Button } from "../styles/"
import { Green } from "../variables/"

export const AdminLogout = () => {

    const dispatch = useDispatch();

    const handleClick = (evt) => {
		evt.preventDefault()
		signOut(auth)
        .then(() => {
            console.log("logout");
            dispatch(updateAdminUser(null))
        })
        .catch((error) => {
            console.log(error);
        });
	}

	return (
		<Button bgcolour={ Green } onClick={ handleClick }>Logout</Button>
	)
}
