import React from "react"
import styled from 'styled-components'

import WhatsAppLogoImage from "../../images/logos/whatsapp-logo.svg"
import { Grey, FontSize } from "../variables/"

const Wrapper = styled.a`
	display: flex;
	align-items: center;
	${FontSize.sm}
	color: inherit;
	text-decoration: none;
	background: #25D366;
	border-radius: 20px;
	padding: 15px;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;	
	&:hover {
		transform: scale(1.02);
	}
`
const WhatsAppLogo = styled(WhatsAppLogoImage)`
	width: 100%;
	max-width: 60px;
	margin-right: 10px;
`
export const WhatsAppLink = () => {

	return (
		<>
			{process.env.GATSBY_WHATSAPP_URL &&
				<Wrapper href={process.env.GATSBY_WHATSAPP_URL} target="_blank">
					<WhatsAppLogo/>
					Click here to visit our WhatsApp page and continue your claim.
				</Wrapper>
			}
		</>
	)
}
