import React from "react"

export const List = ({ list }) => {
	return (
		list.map((item, i, listItems) => {
			return (
				<span key={i}>
					{item}{(i + 1) < listItems.length && ', '}
				</span>
			)
		})
	)
}
