import React, { useState } from "react"
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Button } from "../styles/"
import { ClaimContractPDF, ClaimSummaryPDF } from "./"

export const DownloadClaim = ({ claimData, summary, text, isLink }) => {

	const [linkText] = useState(text ?? (summary ? 'Download summary' : 'Download contract'));

	return (
		<>
			{claimData &&
				<PDFDownloadLink 
					document={
						summary ? <ClaimSummaryPDF claimData={claimData} /> : <ClaimContractPDF claimData={claimData} />
					} 
					fileName={claimData.emailAddress+'-claim'+(summary ? '-summary' : '-contract')+'.pdf'}>
					{isLink ?
						<Button simple="true">{linkText}</Button>
					:
						<Button>{linkText}</Button>
					}
					
				</PDFDownloadLink>
			}
		</>
	)
}
